@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Zoho Puvi';
  src: url('/src/assets/fonts/ZohoPuvi-Thin.ttf') format('truetype');
  font-weight: 100; /* Thin */
  font-style: normal;
}

@font-face {
  font-family: 'Zoho Puvi';
  src: url('/src/assets/fonts/ZohoPuvi-Extralight.ttf') format('truetype');
  font-weight: 200; /* Extra Light */
  font-style: normal;
}

@font-face {
  font-family: 'Zoho Puvi';
  src: url('/src/assets/fonts/ZohoPuvi-Light.ttf') format('truetype');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'Zoho Puvi';
  src: url('/src/assets/fonts/ZohoPuvi-Regular.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Zoho Puvi';
  src: url('/src/assets/fonts/ZohoPuvi-Medium.ttf') format('truetype');
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'Zoho Puvi';
  src: url('/src/assets/fonts/ZohoPuvi-Semibold.ttf') format('truetype');
  font-weight: 600; /* Semi Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Zoho Puvi';
  src: url('/src/assets/fonts/ZohoPuvi-Bold.ttf') format('truetype');
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Zoho Puvi';
  src: url('/src/assets/fonts/ZohoPuvi-Extrabold.ttf') format('truetype');
  font-weight: 800; /* Extra Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Zoho Puvi';
  src: url('/src/assets/fonts/ZohoPuvi-Extrablack.ttf') format('truetype');
  font-weight: 900; /* Extra Black */
  font-style: normal;
}

@font-face {
  font-family: 'Zoho Puvi';
  src: url('/src/assets/fonts/ZohoPuvi-RegularItalic.ttf') format('truetype');
  font-weight: 400; /* Regular Italic */
  font-style: italic;
}

@font-face {
  font-family: 'Zoho Puvi';
  src: url('/src/assets/fonts/ZohoPuvi-BoldItalic.ttf') format('truetype');
  font-weight: 700; /* Bold Italic */
  font-style: italic;
}

@font-face {
  font-family: 'Zoho Puvi';
  src: url('/src/assets/fonts/ZohoPuvi-SemiboldItalic.ttf') format('truetype');
  font-weight: 600; /* Semi Bold Italic */
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}